<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="10"
        md="10"
      >
        <b-card
          no-body
          class="invoice-preview-card shadow-diffuse"
        >
          <div class="container__wrapper">
            <!-- The watermark -->
            <div class="container__watermark">
                {{ t('invoicepreview')}}
            </div>
          </div>

          <div style="position: absolute; right: 1rem; margin-top: 1rem;">
              <feather-icon
                :id="`cfdi-download-preview`"
                class="cursor-pointer text-info"
                icon="ArrowDownCircleIcon"
                size="26"
                @click="downloadPreview"
                v-b-tooltip.hover.top="t('Download Preview')"
              />
          </div>
          <!--<div style="position: absolute; right: 3.5rem; margin-top: 1rem;" v-if="isCancellable">
              <feather-icon
                :id="`cfdi-share`"
                class="cursor-pointer text-info"
                icon="Share2Icon"
                size="26"
                @click="startShareBundle(invoiceData.index.folio_tax)"
                v-b-tooltip.hover.top="t('Share Bundle')"
                v-b-toggle.invoice-sidebar-share
              />
          </div>-->
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <h4 class="text-primary invoice-logo">
                  {{ invoiceData.business.name }}
                </h4>
                <p class="card-text mb-25 text-primary">
                  {{ invoiceData.business.taxid }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceBranchAddress.street }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceBranchAddress.suburb.v }}, {{ invoiceBranchAddress.zip }} {{ invoiceBranchAddress.town.v }}, {{ invoiceBranchAddress.state.v }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.businessTaxpayer ? 
                      invoiceData.businessTaxpayer.k : 
                        ( Array.isArray(invoiceData.business.taxpayer) ? invoiceData.business.taxpayer[0].k : invoiceData.business.taxpayer.k ) }} - 
                        {{ invoiceData.businessTaxpayer ? 
                            invoiceData.businessTaxpayer.v : 
                              ( Array.isArray(invoiceData.business.taxpayer) ? invoiceData.business.taxpayer[0].v : invoiceData.business.taxpayer.v ) }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <!--<div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Folio interno') }}:
                  </p>
                  <p class="invoice-date text-danger" style="width: 300px;">
                    <span class="invoice-number">{{ invoiceData.index.serie }} {{ invoiceData.index.folio }}</span>
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Folio fiscal') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.index.folio_tax }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Fecha de Emision') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.index.edate }}
                  </p>
                </div>-->
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Invoice Type')}}:
                  </p>
                  <p class="invoice-date" style="width: 300px;">
                    {{ invoiceData.type.v }} ({{ invoiceData.type.vsat }})
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.customer"
            class="invoice-padding pt-0"
            style="padding-bottom: 0rem"
          >
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <h6 class="mb-2">
                  {{ t('Invoice To') }}:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.customer.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.taxid }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.address.suburb.v }}, {{ invoiceData.customer.address.zip }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.address.town.v }}, {{ invoiceData.customer.address.state.v }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.customer.email }}
                </p>
              </div>

              <div class="mt-md-0 mt-2">
                <h6 class="mb-2">
                  &nbsp;
                </h6>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Moneda') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.payment.currency.k }} - {{ invoiceData.payment.currency.v }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Payment Method') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.payment.method.k }} - {{ invoiceData.payment.method.v }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Payment Form') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.payment.form.k }} - {{ invoiceData.payment.form.v }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Invoice Use') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.customerInvoiceuse ? 
                        invoiceData.customerInvoiceuse.k : 
                          invoiceData.customer.invoiceuse.k }} - 
                            {{ invoiceData.customerInvoiceuse ? 
                                invoiceData.customerInvoiceuse.v : 
                                  invoiceData.customer.invoiceuse.v }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('TaxPayer Type') }}:
                  </p>
                  <p class="invoice-date" style="width: 300px;">
                    {{ invoiceData.customerTaxpayer ? 
                        invoiceData.customerTaxpayer.k : 
                          invoiceData.customer.taxertype.k }} - 
                            {{ invoiceData.customerTaxpayer ? 
                                invoiceData.customerTaxpayer.v : 
                                  invoiceData.customer.taxertype.v }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <div class="rw" v-if="invoiceData.operation">
            <div class="c16" style="padding-left: 0rem; padding-right: 0rem; padding-top: 0.5rem;">
              <div class="rw" style="padding-left: 1.75rem; padding-right: 1.75rem; padding-top: 0.25rem; border-top: 1px solid #ebe9f1;">
                <div class="c16">
                  <h6>
                    {{ t('relsustit') }} <span class="text-danger">{{invoiceData.operation.cfdi.index.serie}} {{invoiceData.operation.cfdi.index.folio}}</span>
                  </h6>
                </div>
              </div>
              <div class="rw" style="padding-left: 1.75rem; padding-right: 1.75rem; padding-bottom: 0.25rem;">
                <div class="c2">
                  <b-form-group
                      :label="t('Folio')"
                      label-for="docrelated-folio"
                      style="margin-bottom: 0.25rem; "
                    >
                      <div id="docrelated-folio" style="font-size: 9.5pt; font-weight:500;"> {{invoiceData.operation.cfdi.index.serie}} {{invoiceData.operation.cfdi.index.folio}}</div>
                  </b-form-group>
                </div>
                <div class="c4">
                  <b-form-group
                      :label="t('Folio fiscal')"
                      label-for="docrelated-taxfolio"
                      style="margin-bottom: 0.25rem; "
                    >
                      <div id="docrelated-taxfolio" style="font-size: 9.5pt; font-weight:500;">{{invoiceData.operation.cfdi.index.folio_tax}}</div>
                  </b-form-group>
                </div>
                <div class="c4">
                  <b-form-group
                      :label="t('Tipo de Relacion')"
                      label-for="docrelated-relationtype"
                      style="margin-bottom: 0.25rem; "
                    >
                      <div id="docrelated-relationtype" style="font-size: 9.5pt; font-weight:500;">{{invoiceData.operation.meta.sat_action.k}} - {{invoiceData.operation.meta.sat_action.v}}</div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          

          <!-- Invoice Description: Table -->
          <div class="rw">
            <div class="c16" style="padding-left: 0rem; padding-right: 0rem; padding-top: 0.5rem;" v-for="(item, index) in invoiceData.items" :key="index" ref="row">
              <div class="rw" style="padding-left: 1.75rem; padding-right: 1.75rem; padding-top: 0.25rem; padding-bottom: 0.25rem; border-top: 1px solid #ebe9f1;">
                <div class="c2">
                  <div class="rw">
                    <b-form-group
                      :label="t('Id')"
                      :label-for="`item-id-${index}`"
                      style="margin-bottom: 0.25rem; "
                    >
                        <div :id="`item-id-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.id}}</div>
                    </b-form-group>
                  </div>
                  <div class="rw">
                    <b-form-group
                      :label="`${t('Sat Class')} / ${t('Sat Unit')}`"
                      :label-for="`item-satid-${index}`"
                      style="margin-bottom: 0.25rem; "
                    >
                        <div :id="`item-satid-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.satid}} / {{item.satu}}</div>
                    </b-form-group>
                  </div>
                </div>
                <div class="c12">
                  <div class="rw" style="margin-left: -0.5rem; margin-right: -0.5rem;">
                    <div class="c2">
                      <b-form-group
                        :label="t('Qty')"
                        :label-for="`item-qty-${index}`"
                        style="margin-bottom: 0.25rem;"
                      >
                        <div :id="`item-qty-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.qty}}</div>
                      </b-form-group>
                    </div>
                    <div :class="[item.price_t.ieps ? 'c8' : 'c10']">
                      <b-form-group
                        :label="t('Description')"
                        :label-for="`item-desc-${index}`"
                        style="margin-bottom: 0.25rem; "
                      >
                        <div :id="`item-desc0-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.name}}</div>
                      </b-form-group>
                    </div>
                    <div class="c2">
                      <b-form-group
                        :label="t('Amount')"
                        :label-for="`item-price-${index}`"
                        style="margin-bottom: 0.25rem; "
                      >
                          <div :id="`item-price-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.price.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                      </b-form-group>
                    </div>
                    <div class="c2" v-if="item.price_t.ieps">
                      <b-form-group
                        :label="`${t('IEPS')} (${item.taxes.ieps_t.t === 'rate' ? item.taxes.ieps_t.r + '%' : 'Cuota'})`"
                        :label-for="`item-ieps-${index}`"
                      >
                        <div :id="`item-ieps-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.price_t.ieps.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                      </b-form-group>
                    </div>
                    <div class="c2">
                      <b-form-group
                        :label="t('Discount')"
                        :label-for="`item-discount-${index}`"
                        style="margin-bottom: 0.25rem; "
                      >
                        <div :id="`item-discount-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.discount.value.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="rw" style="margin-left: -0.5rem; margin-right: -0.5rem;" v-if="item.config === 'fg.leasing.realstate'">
                    <div class="c4">
                        <b-form-group
                          :label="t('Cadastral Key')"
                          :label-for="`item-leasing-cadastral-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <div :id="`item-leasing-cadastral-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.extra.lease.id}}</div>
                        </b-form-group>
                      </div>
                      <div class="c12">
                      </div>
                  </div>
                  <div class="rw" style="margin-left: -0.5rem; margin-right: -0.5rem;" v-if="item.config === 'fg.school.private'">
                      <div class="c3">
                        <b-form-group
                          :label="t('RVOE / CCT')"
                          :label-for="`item-school-rvoe-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <div :id="`item-school-rvoe-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.extra.school.auth}}</div>
                        </b-form-group>
                      </div>
                      <div class="c3">
                        <b-form-group
                          :label="t('School Level')"
                          :label-for="`item-school-level-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <div :id="`item-school-level-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.extra.school.level.k}}</div>
                        </b-form-group>
                      </div>
                      <div class="c6">
                        <b-form-group
                          :label="t('Name')"
                          :label-for="`item-school-name-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <div :id="`item-school-name-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.extra.school._alummni_name}}</div>
                        </b-form-group>
                      </div>
                      <div class="c4">
                        <b-form-group
                          :label="t('CURP')"
                          :label-for="`item-school-curp-${index}`"
                          style="margin-bottom: 0.25rem;"
                        >
                          <div :id="`item-school-curp-${index}`" style="font-size: 9.5pt; font-weight:500;">{{item.extra.school._alummni_popid}}</div>
                        </b-form-group>
                      </div>                      
                    </div>
                  
                </div>  
                <div class="c2">
                  <div class="rw">
                    <b-form-group
                      :label="t('Subtotal')"
                      :label-for="`item-subtotal-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-subtotal-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div>
                  <div class="rw" v-if="item.taxes.ieps_t">
                    <b-form-group
                      :label="`${t('IEPS Traslate')} (${item.taxes.ieps_t.t === 'rate' ? item.taxes.ieps_t.r + '%' : 'Cuota'})`"
                      :label-for="`item-ieps-traslate-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-ieps-traslate-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.taxes.ieps_t.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div>
                  <div class="rw" v-if="item.taxes.iva_t">
                    <b-form-group
                      :label="`${t('IVA')} (${item.taxes.iva_t.r}%)` "
                      :label-for="`item-iva-traslate-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-iva-traslate-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.taxes.iva_t.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div>
                  
                  <div class="rw" v-if="item.taxes.iva_r">
                    <b-form-group
                      :label="`${t('IVA Ret')} (${item.taxes.iva_r.r}%)` "
                      :label-for="`item-iva-retain-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-iva-retain-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.taxes.iva_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div> 
                  <div class="rw" v-if="item.taxes.isr_r">
                    <b-form-group
                      :label="`${t('ISR Ret')} (${item.taxes.isr_r.r}%)` "
                      :label-for="`item-isr-retain-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-isr-retain-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.taxes.isr_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div> 
                  <div class="rw" v-if="item.taxes.ieps_r">
                    <b-form-group
                      :label="`${t('IEPS Ret')} (${item.taxes.ieps_r.t === 'rate' ? item.taxes.ieps_r.r + '%' : 'Cuota'})` "
                      :label-for="`item-ieps-retain-${index}`"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div :id="`item-ieps-retain-${index}`" style="font-size: 9.5pt; font-weight:500; text-align: right;">{{ item.taxes.ieps_r.a.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>

            <!-- Invoice Description: Total -->
            
          <div class="rw">
            <div class="c16" style="padding-left: 0rem; padding-right: 0rem; padding-top: 0.5rem;">
              <div class="rw" style="padding-left: 1.75rem; padding-right: 1.75rem; padding-top: 0.25rem; padding-bottom: 0.25rem; border-top: 1px solid #ebe9f1;">
                <div class="c2" style="margin-left: auto;">
                  <b-form-group
                      :label="t('Subtotal')"
                      label-for="invoice-subtotal"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-subtotal" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.summary.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2" v-if="invoiceData.summary.discount > 0.0">
                  <b-form-group
                      :label="t('Discount')"
                      label-for="invoice-discount"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-discount" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.summary.discount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2" v-if="invoiceData.taxes.total_iva_t > 0.0">
                  <b-form-group
                      :label="t('IVA')"
                      label-for="invoice-total-iva-t"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-total-iva-t" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.taxes.total_iva_t.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2" v-if="invoiceData.taxes.total_ieps_t > 0.0">
                  <b-form-group
                      :label="t('IEPS')"
                      label-for="invoice-total-ieps-t"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-total-ieps-t" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.taxes.total_ieps_t.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2" v-if="invoiceData.taxes.total_iva_r > 0.0">
                  <b-form-group
                      :label="t('IVA Ret')"
                      label-for="invoice-total-iva-r"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-total-iva-r" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.taxes.total_iva_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2" v-if="invoiceData.taxes.total_isr_r > 0.0">
                  <b-form-group
                      :label="t('ISR Ret')"
                      label-for="invoice-total-isr-r"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-total-isr-r" style="font-size: 10.0pt; font-weight:500; text-align: right;">{{ invoiceData.taxes.total_isr_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
                <div class="c2">
                  <b-form-group
                      :label="t('Total')"
                      label-for="invoice-total"
                      style="margin-bottom: 0.25rem; width: 100%;"
                    >
                      <div id="invoice-total" style="font-size: 11.0pt; font-weight:800; text-align: right;">{{ invoiceData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
                    </b-form-group>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Spacer -->
          <hr class="invoice-spacing" v-if="invoiceData.observations !== ''">
          <!-- Note -->
          <b-card-body class="invoice-padding pt-0" v-if="invoiceData.observations !== ''">
            <span class="font-weight-bold">{{ t('Observations') }} </span>
            <span>{{ invoiceData.observations }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0" v-else>
            
          </b-card-body>

          <!--<hr class="invoice-spacing">-->
          <!-- Note -->
          <!--<b-card-body class="invoice-padding pt-0">
            <div class="rw">
              <div class="c2" style="padding: 0rem;">
                <img :src="invoiceEntities[0].qr.startsWith('https') ? invoiceEntities[0].qr : `data:image/png;base64,${invoiceEntities[0].qr}`">                
              </div>
              <div class="c3" style="padding: 0rem;">
                <div class="rw">
                  <b-form-group
                    :label="t('Serie Certificado')"
                    label-for="cfdi-serie"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="cfdi-serie" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.cert_serial_business : '' }}</div>
                  </b-form-group>
                </div>
                <div class="rw">
                  <b-form-group
                    :label="t('Serie Certificado SAT')"
                    label-for="cfdi-serie-sat"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="cfdi-serie-sat" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.cert_serial_sat : '' }}</div>
                  </b-form-group>
                </div>
                <div class="rw">
                  <b-form-group
                    :label="t('RFC Proveedor de Certificacion')"
                    label-for="cfdi-sealer-taxid"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="cfdi-sealer-taxid" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.sealer_taxid : '' }}</div>
                  </b-form-group>
                </div>
                <div class="rw">
                  <b-form-group
                    :label="t('Fecha y hora de Certificacion')"
                    label-for="cfdi-sealer-date"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="cfdi-sealer-taxid" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.seal_date : '' }}</div>
                  </b-form-group>
                </div>                
                
              </div>
              <div class="c11" style="padding-left: 0rem; padding-right: 0rem;">
                <div class="rw">
                  <b-form-group
                    :label="t('CadenaOriginal')"
                    label-for="item-emmited-seal"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="item-emmited-seal" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.source_string_sat : '' }}</div>
                  </b-form-group>
                </div>
                <div class="rw">
                  <b-form-group
                    :label="t('Sello Digital del CFDI')"
                    label-for="item-emmited-seal"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="item-emmited-seal" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.seal_cfdi : ''}}</div>
                  </b-form-group>
                </div>
                <div class="rw">
                  <b-form-group
                    :label="t('Sello Digital del SAT')"
                    label-for="item-emmited-seal"
                    style="margin-bottom: 0.25rem; width: 100%;"
                  >
                    <div id="item-emmited-seal" style="font-size: 8pt; text-overflow: ellipsis;">{{ invoiceEntities[0] && invoiceEntities[0].cfdi ? invoiceEntities[0].cfdi.seal_sat : '' }}</div>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-card-body>-->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import {
  VBTooltip, BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import cfdiStoreModule from './cfdiStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentPaymentToCancel: {},
      currentCFDIToShare: {}
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BAlert,
    BLink
  },
  computed: {
    isCancellable: {
      get() {
        return this.invoiceData && this.invoiceData.lifecycle !== 'cfdi.cancel.start' && this.invoiceData.lifecycle !== 'cfdi.cancel.accepted' && this.invoiceData.lifecycle !== 'cfdi.cancel.rejected'
      }
    },
    cancelLabel: {
      get() {
        if(this.invoiceData){
          switch(this.invoiceData.lifecycle) {
            case 'cfdi.cancel.start':
              return this.t('cfdi_cancel_start_label')
            case 'cfdi.cancel.accepted':
              return this.t('cfdi_cancel_accepted_label')
            case 'cfdi.cancel.rejected':
              return this.t('cfdi_cancel_rejected_label')
            default:
              return ''
          }
        }

        return ''
      }
    }

  },
  methods: {
    startInvoiceReplace(uuid) {
      this.$router.replace({ name: 'fg-cfdi-replace', params: { ruuid: uuid }})
        .then(() => {
          
        })
    },
    startPaymentCancel(i) {
      this.currentPaymentToCancel = this.invoiceEntities[i]
    },
    startShareBundle(uuid) {
      this.currentCFDIToShare = { uuid: uuid, type: 'share.email', to: this.invoiceData.customer.email, subject: ''}
    },
    downloadPreview() {
      this.$store.dispatch('fg-cfdi/fetchPreview',{ uuid: this.invoiceUUID })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'preview_' + this.invoiceData.business.taxid + '_' + this.invoiceData.customer.taxid + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    downloadPayment(uuid) {
      this.$store.dispatch('fg-cfdi/fetchBundle',{ uuid: uuid , type: 'complemento_pago'})
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payment_' + uuid + '.zip'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
          console.log(error);
      });
    }

  },
  mounted() {
    this.$store.dispatch('fg-cfdi/fetchCfdi',{ uuid: this.$route.params.uuid })
      .then(response => { 
          this.invoiceData = response.data.cfdi 
          this.invoiceUUID = response.data.cfdi.uuid 
          this.invoiceBranchAddress = response.data.cfdi.business.branch ? response.data.cfdi.business.branch.address : response.data.cfdi.business.address.main
        })
  },
  setup() {
    const invoiceData = ref(null)
    const invoiceEntities = ref([])
    const invoiceUUID = ref('')
    /** fer@arkeline.io Object for supporting branch address or main address */
    const invoiceBranchAddress = ref(null)

    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      invoiceEntities,
      invoiceUUID,
      invoiceBranchAddress,
      printInvoice,
      /** lsandoval: 11/04/2022 t supplier */
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.rw {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}

.menu_sticky {
  position: sticky;
  top: 170px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebe9f1;
}

.c1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 6.25%;
  max-width: 6.25%;
}

.c2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.c3 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 18.75%;
  max-width: 18.75%;
}

.c4 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 25.0%;
  max-width: 25.0%;
}

.c6 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.c8 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 50.0%;
  max-width: 50.0%;
}

.c10 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 62.5%;
  max-width:62.5%;
}

.c11 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 68.75%;
  max-width: 68.75%;
}

.c12 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 75.0%;
  max-width: 75.0%;
}

.c13 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 81.25%;
  max-width: 81.25%;
}

.c14 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.c16 {
  padding-right: 0.5rem;
  padding-left:0.5rem;
  flex: 0 0 100.0%;
  max-width: 100.0%;
}

.shadow-diffuse{
  border-radius: 0px !important;
  box-shadow:0 1px 1px rgba(0,0,0,0.08),0 2px 2px rgba(0,0,0,0.12),0 4px 4px rgba(0,0,0,0.16),0 8px 8px rgba(0,0,0,0.2) !important
  }

.container__wrapper {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Absolute position */
    left: 0px;
    position: absolute;
    top: 0px;

    /* Take full size */
    height: 100%;
    width: 100%;
}
.container__watermark {
    /* Text color */
    color: rgba(0, 0, 0, 0.2);

    /* Text styles */
    font-size: 3.5rem;
    font-weight: bold;
    text-transform: uppercase;

    /* Rotate the text */
    transform: rotate(-45deg);

    /* Disable the selection */
    user-select: none;
}

</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
